import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resetError } from '../../features/user/userSlice.js';
import logocat from '../../assets/transparent-without-circle.png'
import { getAuth, sendEmailVerification } from 'firebase/auth';
import iconpaw from '../../assets/icon-paw.svg';
import { getErrorMsg } from '../../utils/errorMessages.js';
import "firebase/compat/auth";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LoginForm = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error: errorCode } = useSelector(state => state.auth);
    const [requestInProcess, setRequestInProcess] = useState(null);
    const [showEmailVerification, setShowEmailVerification] = useState(null);


    const handleResendEmail = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          try {
            await sendEmailVerification(user);
            alert('Email de vérification renvoyé ! Vérifiez votre boîte mail.');
          } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'email de vérification :', error);
            alert('Erreur lors de l\'envoi de l\'email. Veuillez réessayer plus tard.');
          }
        }
      };

    useEffect(() => {
        if (errorCode) {
            console.log(errorCode)
            setError(getErrorMsg(errorCode));
        } else {
            setError(null);
        }
        return () => {
            setError(null); // réinitialisez l'erreur lorsque le composant se démonte
        };

    }, [errorCode, setError]);


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRequestInProcess(true);

        try {
            const actionResult = await dispatch(loginUser({ email, password }));

            if (loginUser.fulfilled.match(actionResult)) {
                setRequestInProcess(false);
                navigate('/');
            } else if (loginUser.rejected.match(actionResult)) {
                setRequestInProcess(false);
                // console.log(actionResult)
                if(actionResult.payload.message === "EMAIL_NOT_VERIFIED"){
                    setError(getErrorMsg(actionResult.payload.message));
                    setShowEmailVerification(true);
                } else {
                    setError(getErrorMsg(actionResult.payload.code));
                }
                
            }
        } catch (error) {
            console.log(error)
            setError(getErrorMsg(error.code));
        }
    };


    const handleNavigation = () => {
        dispatch(resetError());
    };

    useEffect(() => {
        dispatch(resetError());
    }, []);

    return (
        <div className='auth-page'>

            <Helmet>
                <meta name="description" content="Kappze est le premier outil dans la gestion des chats errants à destination des mairies et associations. Les chats libres, vraiment libres." />
            </Helmet>
            <div className="auth-section1">
                <div className="background-overlay"></div>
                <h1>Kappze</h1>
                <h2>Le 1er outil de suivi éthique des chats errants</h2>
            </div>

            <div className="auth-section2">
                <h1 className='auth-section2-title'>{t('title')}</h1>
                <div>
                    <img src={logocat} alt="Logo" style={{ height: '200px', backgroundColor: 'none' }} />
                </div>
                <form onSubmit={handleSubmit} className='authForm  password-input'>
                    <div className='auth-input'>
                        <label htmlFor="email">{t('email')}</label>
                        <input type="email" id="email" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className='auth-input'>
                        <label htmlFor="password">{t('password')}</label>
                        <input type="password" id="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <button type="submit" className="register-button">
                        {t('logIn')} <img src={iconpaw} alt="Icon Paw" className="button-icon" />
                    </button>
                    {requestInProcess && <div className="loaderPayment"></div>}



                </form>
                {errorMessage && <p className="error-message" style={{ color: "rgb(135, 41, 41)", fontWeight: 'bolder' }}>{errorMessage}</p>}

{showEmailVerification && (
    <button onClick={handleResendEmail}>Renvoyer l'email de vérification</button>
)}
                <div className="authForm-footer">
                    <p className='auth-link-registration'>{t('notRegisterYet')}
                        <NavLink to="/registration" >
                            <span onClick={handleNavigation} style={{ marginRight: '16px' }}> {t('register')}</span>
                        </NavLink>
                    </p>
                    <p className='auth-link-forgotpassword'>
                        <NavLink to="/password">
                            <span style={{ marginRight: '16px', textDecoration: 'underline' }}>{t('forgotPassword')}</span>
                        </NavLink>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;